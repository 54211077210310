import React, { FC } from "react";

import { MashroomTeamType } from "../../types";
import Subtitle from "../Subtitle";

import css from "./index.module.css";

type Props = {
  content: MashroomTeamType;
};

const MashroomTeam: FC<Props> = ({ content }) => (
  <div className={css.container}>
    <Subtitle className={css.title} content={content.title} />
    <div className={css.members}>
      <div className={css.inner}>
        {content.members.map((m) => (
          <div key={m.name} className={css.member}>
            <div
              className={css.memberAvatar}
              style={{ backgroundColor: m.background }}
            >
              <picture>
                <source
                  srcSet={`${m.avatar.url}.webp, ${m.avatar2x.url}.webp 2x`}
                  type="image/webp"
                />
                <source
                  srcSet={`${m.avatar.url}, ${m.avatar2x.url} 2x`}
                  type="image/png"
                />
                <img
                  alt={m.name}
                  decoding="async"
                  loading="lazy"
                  src={m.avatar.url}
                  width={m.avatar.width}
                />
              </picture>
            </div>
            <div className={css.memberName}>{m.name}</div>
            <div
              className={css.memberPosition}
              dangerouslySetInnerHTML={{ __html: m.position }}
            />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default MashroomTeam;
